<template>
  <PageFullWidth class="home-site" :key="key" :page="page" />
</template>
<style>
@import "/p/wp-content/themes/tigros2020/styles-common.css";
</style>
<script>
import page from "~/mixins/page";

import CMSService from "~/service/cmService";
import PageFullWidth from "@/components/wordpress/PageFullWidth.vue";
export default {
  name: "HomeSite",
  data() {
    return {};
  },
  components: {
    PageFullWidth
  },
  mixins: [page],
  methods: {
    async fetchPage() {
      let data = await CMSService.getPage("homepage");
      if (data.length > 0) {
        this.page = data[0];
        this.key++;
      }
    }
  },
  created() {
    this.fetchPage();
  },
  metaInfo() {
    if (this.page) {
      return {
        link: [
          {
            rel: "canonical",
            href: "https://www.tigros.it/"
          }
        ]
      };
    }
  }
};
</script>
